import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';

const LayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  header {
    flex-shrink: 0;
  }

  main {
    flex-grow: 1;
  }

  footer {
    align-self: flex-end;
  }
`;

interface Props {
  children: ReactNode;
  mainClass?: string;
}

export default function Layout(props: Props) {
  const { children, mainClass } = props;

  return (
    <LayoutStyled data-testid="App">
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="anonymous" />
        <link href="https://fonts.googleapis.com/css?family=Bitter%7CAlegreya+Sans&display=swap" rel="stylesheet" />
      </Helmet>
      <Header />
      <main className={mainClass}>
        {children}
      </main>
      <Footer />
    </LayoutStyled>
  );
}
