/* eslint-disable react/no-danger */
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { em } from 'polished';
import React, { useEffect, useRef, RefObject } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { isShareSupported, shareUrl } from '../utils/utils';
import ButtonIcon from './ButtonIcon';

const StyledHeader = styled.header`
  width: 100%;
  border-bottom: ${em(2)} solid var(--secondaryLight-color);
  background-color: var(--primaryDarkAlpha-color);
  transition: background-color 1000ms linear;
  position: relative;

  .inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${em(8)} ${em(20)};
  }

  h1 {
    white-space: pre;
    font-size: 1.5em;
    height: 100%;
  }

  a {
    color: var(--white-color);
    margin-right: auto;
  }

  img {
    max-width: 100%;
  }

  .header {
    display: grid;
    grid-template-columns: ${em(30)} 1fr;
    grid-gap: ${em(8)};

    &:hover {
      color: var(--secondaryLight-color);
      filter: brightness(1.3);
    }
  }

  .com {
    font-size: 0.8em;
    color: var(--primaryLight-color);
  }

  .buttons {
    display: none;
    flex-direction: row;
    margin-left: ${em(20)};
    opacity: 0;

    &:first-child {
      margin-left: 0;
    }

    > button {
      color: var(--white-color);
      font-size: 1.5em;
      animation: fadeInFromNone 0.4s ease-out;
      margin-left: ${em(5)};

      &:hover {
        color: var(--secondaryLight-color);
      }
    }
  }
`;

interface Props {
  className?: string;
}

export default function Header(props: Props) {
  const { className } = props;
  const buttonsRef: RefObject<HTMLDivElement> = useRef(null);

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }

      file(absolutePath: {
        regex: "/\\/images\\/app-icon\\.png/"
      }) {
        childImageSharp {
          fixed(width: 128) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const AppIcon = data.file.childImageSharp.fixed.src;

  useEffect(() => {
    buttonsRef.current.style.display = 'flex';
    buttonsRef.current.style.opacity = '1.0';
  }, []);

  return (
    <StyledHeader className={className}>
      <div className="page-container inner">
        <Link to="/">
          <div className="header">
            <img src={AppIcon} alt="Classic Tinker Icon" />
            <h1>{data.site.siteMetadata.title}<span className="com">.com</span></h1>
          </div>
        </Link>
        <div className="buttons" ref={buttonsRef}>
          {
            isShareSupported() && (
              <ButtonIcon onClick={shareUrl} label="share">
                <FontAwesomeIcon icon={faShareAlt} />
              </ButtonIcon>
            )
          }
        </div>
      </div>
    </StyledHeader>
  );
}
