import {
  useContext, useEffect, useMemo, useRef,
} from 'react';
import { debounce } from 'lodash';
import isEqual from 'react-fast-compare';
import { GlobalContext, themes } from '../context/global-context';
import { Faction } from '../../../types/generated';

export function useTheme(faction: Faction) {
  const { setTheme } = useContext(GlobalContext);

  useEffect(() => {
    const theme = faction === 'horde' ? themes.horde : themes.alliance;
    setTheme(theme);
    return () => {
      setTheme(themes.alliance);
    };
  }, [faction]);
}

export function useDebounce(func) {
  return useRef(debounce(func, 400, { leading: true, trailing: true }));
}

function isArrayEqual(array1, array2) {
  return isEqual(array1, array2);
}

function useDeepCompareMemoize(value) {
  const ref = useRef();

  if (!isArrayEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepMemo(callback, dependencies) {
  return useMemo(callback, useDeepCompareMemoize(dependencies));
}
