import React from 'react';
import styled from 'styled-components';
import { em } from 'polished';
import Layout from '../client/common/layout/Layout';
import SEO from '../client/common/layout/SEO';
import Location from '../client/locations/components/Location';
import Breadcrumbs from '../client/common/components/Breadcrumbs';
import { getContinent, getLocationLink } from '../shared/utils';
import { FlightPath, FlightPoint } from '../types/generated';

const Page = styled.div`
  display: grid;
  grid-template-rows: max-content;
  grid-gap: ${em(20)};

  .breadcrumbs {
    font-size: 1.5em;
  }
`;

interface Context {
  point: FlightPoint;
  connectedPaths: FlightPath[];
}

interface Props {
  location: any;
  pageContext: Context;
}

// eslint-disable-next-line react/prop-types
const LocationTemplate = (props: Props) => {
  const { location, pageContext } = props;
  const { point, connectedPaths } = pageContext;
  const links = [
    {
      link: '/locations/',
      title: 'Locations',
    },
    {
      title: point.location,
    },
  ];

  return (
    <Layout mainClass="page-padding-height">
      <SEO
        title={`${point.location} | WoW Classic`}
        description={`WoW Classic location information on ${point.location} in ${getContinent(point.continent)}.`}
        location={location}
        extraSchema={[
          {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [{
              '@type': 'ListItem',
              position: 1,
              name: 'Locations',
              item: 'https://classictinker.com/locations/',
            }, {
              '@type': 'ListItem',
              position: 2,
              name: point.location,
              item: `https://classictinker.com/locations${getLocationLink(point.location)}/`,
            }],
          },
        ]}
      />
      <Page>
        <div className="page-container page-padding-width content">
          <Breadcrumbs className="breadcrumbs" links={links} />
          <Location point={point} connectedPaths={connectedPaths} />
        </div>
      </Page>
    </Layout>
  );
};

export default LocationTemplate;
