import React from 'react';
import styled from 'styled-components';
import { em } from 'polished';
import { capitalize, getContinent, getLocationInfo } from '../../../shared/utils';
import { FlightPoint } from '../../../types/generated';

const StyledLocationInfo = styled.table`
  table, th, td {
    border: ${em(1)} solid var(--secondaryLightAlpha-color);
  }

  td {
    padding: ${em(5)} ${em(15)} ${em(5)} ${em(5)};

    &:first-child {
      font-weight: bold;
    }
  }
`;

interface Props {
  point: FlightPoint;
}

function LocationInfo(props: Props) {
  const { point } = props;
  const { levels } = point;

  return (
    <StyledLocationInfo>
      <tbody>
        <tr>
          <td>Phase</td>
          <td>{point.phase || 1}</td>
        </tr>
        <tr>
          <td>Type</td>
          <td>{capitalize(point.type) || 'Town'}</td>
        </tr>
        {
          levels && (
            <>
              <tr>
                <td>Advised Level</td>
                <td>{levels.min !== levels.max ? `${levels.min} - ${levels.max}` : levels.max}</td>
              </tr>
              <tr>
                <td>Minimum Level</td>
                <td>{levels.entry}</td>
              </tr>
            </>
          )
        }
        <tr>
          <td>Continent</td>
          <td>{getContinent(point.continent)}</td>
        </tr>
        <tr>
          <td>Zone</td>
          <td>{getLocationInfo(point.location).zone}</td>
        </tr>
        <tr>
          <td>Faction</td>
          <td>{capitalize(point.faction)}</td>
        </tr>
        <tr>
          <td>Map Coords</td>
          <td>{point.mapLoc || 'Unknown'}</td>
        </tr>
        {
          point.type !== 'raid' && point.type !== 'dungeon' && (
            <tr>
              <td>Hearthstone</td>
              <td>{point.hearthstone ? 'Yes' : 'No'}</td>
            </tr>
          )
        }
      </tbody>
    </StyledLocationInfo>
  );
}

export default LocationInfo;
