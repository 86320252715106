import { em } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { version } from '../../../../package.json';

const FooterStyled = styled.footer`
  background-color: var(--secondaryDark-color);
  color: var(--primaryLight-color);
  width: 100%;

  .inner {
    display: flex;
    min-height: ${em(105)};
    padding: ${em(30)};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    > * {
      margin-bottom: ${em(8)};

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyled>
      <div className="page-container inner">
        <div>Version: {version}</div>
        <Link to="/copyright-notices/">Copyright Notices</Link>
      </div>
    </FooterStyled>
  );
}
