import { em } from 'polished';
import React, { RefObject, ReactNode } from 'react';
import styled from 'styled-components';

const ButtonStyled = styled.button`
  color: white;
  background-color: var(--primaryDark-color);
  transition: background-color 1000ms linear;
  cursor: pointer;
  font-family: 'Bitter', serif;
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
  padding: ${em(8)} ${em(15)};
  box-shadow: ${em(2)} ${em(2)} ${em(10)} var(--primaryAlpha-color);
  border: ${em(2)} outset var(--primaryLight-color);
  touch-action: manipulation;

  &:hover {
    filter: brightness(1.2);
  }
`;

interface Props {
  autoFocus?: boolean;
  children: ReactNode;
  className?: string;
  copyText?: string;
  innerRef?: RefObject<HTMLInputElement>;
  onClick?: (e?) => void;
}

export default function Button(props: Props) {
  const {
    autoFocus, children, className, copyText, innerRef, onClick,
  } = props;

  return (
    <ButtonStyled
      className={className}
      ref={innerRef}
      data-clipboard-text={copyText}
      onClick={onClick}
      autoFocus={autoFocus}
    >
      {children}
    </ButtonStyled>
  );
}
