import { kebabCase } from 'lodash';
import { Location } from '../types';
import { FlightPoint } from '../types/generated';

export function fromPointsToMap(points: FlightPoint[] = []) {
  return points.reduce((prev, curr) => {
    if (prev.has(curr.location)) {
      // set shared points to be both factions
      const flightPoint = prev.get(curr.location);
      if (curr.faction !== flightPoint.faction) {
        flightPoint.faction = 'both';
      }
      prev.set(curr.location, flightPoint);
    } else {
      prev.set(curr.location, curr);
    }
    return prev;
  }, new Map());
}

export function capitalize(text: string) {
  if (!text) return '';
  if (text.toLocaleLowerCase() === 'nightelf') return 'NightElf';
  return text.charAt(0).toLocaleUpperCase() + text.toLocaleLowerCase().slice(1);
}

export function getLocationInfo(text: Location) {
  const [location, zone] = text.split(',');
  return {
    location,
    zone,
  };
}

export function getContinent(num) {
  if (num === 1) {
    return 'Eastern Kingdoms';
  }
  return 'Kalimdor';
}

export function getLocationLink(text: any) {
  const { location } = getLocationInfo(text);
  return `/locations/${kebabCase(location)}`;
}
