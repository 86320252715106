import React from 'react';
import styled from 'styled-components';
import { em } from 'polished';
import { Link } from 'gatsby';

const StyledBreadcrumbs = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline;
  }
  
  li+li:before {
    padding: 0 ${em(8)};
    content: "/\\00a0";
  }
`;

interface Props {
  className?: string;
  links: {
    link?: string;
    title: string;
  }[];
}

function Breadcrumbs(props: Props) {
  const { className, links } = props;
  return (
    <StyledBreadcrumbs className={className}>
      {
        links.map(link => (
          <li key={link.title}>
            {
              link.link ? (
                <Link to={link.link}>{link.title}</Link>
              ) : <span>{link.title}</span>
            }
          </li>
        ))
      }
    </StyledBreadcrumbs>
  );
}

export default Breadcrumbs;
