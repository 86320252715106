// eslint-disable-next-line import/no-extraneous-dependencies
import { globalHistory } from '@reach/router';
import qs from 'qs';

export type Filter = Record<string, string>;

export function getQueryParamsString(obj) {
  return qs.stringify(obj, {
    filter(prefix, value) {
      if (value === null) {
        return;
      }
      // eslint-disable-next-line consistent-return
      return value;
    },
  });
}

export function getQueryParams(search): any {
  return qs.parse(search, {
    ignoreQueryPrefix: true,
    decoder(str, decoder, charset) {
      const strWithoutPlus = str.replace(/\+/g, ' ');
      if (charset === 'iso-8859-1') {
        // unescape never throws, no try...catch needed:
        return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
      }
      // parse numbers
      if (/^(\d+|\d*\.\d+)$/.test(str)) {
        return parseFloat(str);
      }
      // parse keywords like true and false
      const keywords = {
        true: true,
        false: false,
        null: null,
        undefined,
      };
      if (str in keywords) {
        return keywords[str];
      }
      // parse utf-8 strings
      try {
        return decodeURIComponent(strWithoutPlus);
      } catch (e) {
        return strWithoutPlus;
      }
    },
  });
}

export function isAllowed(filter) {
  const { location: { search } } = globalHistory;
  const queryParams = getQueryParams(search);
  if (Object.keys(filter).length === 0) {
    return true;
  }
  return Object.entries(queryParams).some(([key, value]) => {
    const expectedValue = filter[key];
    return (expectedValue && expectedValue === value);
  });
}

export function getFieldValue(field, defaultValue, filter: Filter) {
  const { location: { search } } = globalHistory;
  const queryParams = getQueryParams(search);
  const { [field]: fieldValue = defaultValue } = queryParams;
  if (!isAllowed(filter)) {
    return defaultValue;
  }
  return fieldValue;
}
