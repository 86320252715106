// eslint-disable-next-line import/no-extraneous-dependencies
import { globalHistory, History } from '@reach/router';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Filter, getFieldValue, getQueryParams, isAllowed,
} from '../utils/location';
import { FormContext } from '../context/form-context';

type HistoryKeys = Exclude<keyof History, 'listen' | 'transitioning'>;
type HistoryLite = Pick<History, HistoryKeys>;

export function useLocation(): HistoryLite {
  const initialState = {
    location: globalHistory.location,
    navigate: globalHistory.navigate,
  };

  const [state, setState] = useState<HistoryLite>(initialState);
  useEffect(() => {
    const removeListener = globalHistory.listen((params) => {
      const { location } = params;
      const newState = Object.assign({}, initialState, { location });
      setState(newState);
    });
    return () => {
      removeListener();
    };
  }, []);

  return state;
}

export function useField<T>(field: string, defaultValue: T, filter: Filter = {}): [T, any] {
  const [value, setValue] = useState<T>(getFieldValue(field, defaultValue, filter));
  const { dispatch } = useContext(FormContext);
  const { location: { search } } = useLocation();
  const isSearchRef = useRef(false);

  // update reducer on value change
  useEffect(() => {
    dispatch({ type: 'update', query: { [field]: value } });
    if (isAllowed(filter) && !isSearchRef.current) {
      dispatch({ type: 'trigger' });
    }
    isSearchRef.current = false;
  }, [value]);

  // update reducer and internal value on search change
  useEffect(() => {
    if (isAllowed(filter)) {
      const queryParams = getQueryParams(search);
      const { [field]: newValue } = queryParams;
      // eslint-disable-next-line no-prototype-builtins
      if (queryParams.hasOwnProperty(field) && value !== newValue) {
        isSearchRef.current = true;
        setValue(newValue);
      }
    }
  }, [search]);

  return [value, setValue];
}
