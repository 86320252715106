import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

// Force default defer to `false` until Helmet works out a bug on IE11
// See: https://github.com/nfl/react-helmet/issues/336
Helmet.defaultProps.defer = false;

interface Props {
  title: string;
  description: string;
  keywords?: string[];
  location?: any;
  statusCode?: number;
  extraSchema?: [any];
  hasSuffix?: boolean;
  noIndex?: boolean;
}

export default function SEO(props: Props) {
  const {
    title, description, keywords = [], location, statusCode = 200,
    extraSchema = [], hasSuffix = false, noIndex = false,
  } = props;

  const { site, file } = useStaticQuery(graphql`
    query SEOQuery {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }

      file(absolutePath: {
        regex: "/\\/images\\/app-icon\\.png/"
      }) {
        childImageSharp {
          fixed(width: 128) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;
  const { siteUrl } = site.siteMetadata;
  const url = location ? `${siteUrl}${location.pathname}` : siteUrl;
  const iconUrl = file.childImageSharp.fixed.src;
  let titleFull = title;
  if (hasSuffix) {
    titleFull += ` | ${site.siteMetadata.title}`;
  }

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url,
      name: titleFull,
    },
    ...extraSchema,
  ];

  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={titleFull}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(schemaOrgJSONLD),
        },
      ]}
      link={[
        statusCode === 200 && {
          rel: 'canonical',
          href: url,
        },
      ]}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:url',
          content: url,
        },
        {
          property: 'og:title',
          content: titleFull,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: `${siteUrl}${iconUrl}`,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:title',
          content: titleFull,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:image',
          content: `${siteUrl}${iconUrl}`,
        },
        ...noIndex ? [{ name: 'robots', content: 'noindex' }] : [],
      ]
        .concat(
          keywords.length > 0
            ? {
              name: 'keywords',
              content: keywords.join(', '),
            }
            : [],
        )}
    />
  );
}
