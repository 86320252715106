import React, { useEffect, useRef, useState } from 'react';
import loadable from '@loadable/component';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { em } from 'polished';
import { capitalize, getLocationLink } from '../../../shared/utils';
import LocationInfo from './LocationInfo';
import Button from '../../common/components/Button';
import { useTheme } from '../../common/hooks';
import { FlightPath, FlightPoint } from '../../../types/generated';

// @ts-ignore
const AsyncMap = loadable(() => import(/* webpackPrefetch: true */ '../../flightmaster/components/Map'), { ssr: false });

const StyledLocation = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-template-areas:
    "info map"
  ;

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "info"
      "map";
  }

  h1 {
    padding-bottom: ${em(5)};
    font-size: 1.5em;
  }

  .info {
    grid-area: info;
    display: grid;
    grid-auto-rows: max-content;
    grid-gap: ${em(20)};
    background-color: var(--secondaryDarkAlpha-color);
    padding: ${em(20)};
  }

  .connection {
    font-size: ${em(17)};

    a {
      display: inline-block;
      padding: ${em(3)} 0;
    }
  }

  .map-wrapper {
    background-color: var(--secondaryDarkAlpha-color);
  }

  .map {
    grid-area: map;
    width: 100%;
    height: 560px;
    z-index: 0;
  }
`;

interface Props {
  point: FlightPoint;
  connectedPaths: FlightPath[];
}

export default function Location(props: Props) {
  const { point, connectedPaths } = props;
  const faction = point.faction === 'both' ? 'alliance' : point.faction;
  const [loaded, setLoaded] = useState(false);
  const mapRef = useRef();
  useTheme(faction);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <StyledLocation>
      <section className="info">
        <div>
          <h1>Info (WoW Classic)</h1>
          <LocationInfo point={point} />
        </div>
        <div>
          {
            connectedPaths.length > 0 && <h1>Connections</h1>
          }
          {
            connectedPaths
              .sort((a, b) => a.transport.localeCompare(b.transport))
              .map((path) => {
                const { endpoints, transport } = path;
                const [, toLoc] = endpoints;
                return (
                  <div key={`${toLoc}${transport}`}>
                    <div className="connection">{capitalize(`${transport}`)} to<span> </span>
                      <Link to={getLocationLink(toLoc)}>
                        {toLoc}
                      </Link>
                    </div>
                  </div>
                );
              })
          }
        </div>
        <div>
          <h1>Directions</h1>
          <p>Get directions to and from &#34;{point.location}&#34; in WoW Classic.</p><br />
          <Link to={`/flight-master/?toLoc=${encodeURIComponent(point.location)}&faction=${faction}`}>
            <Button className="link">Get Directions</Button>
          </Link>
        </div>
      </section>
      <div className="map-wrapper">
        {
          loaded && (
            <AsyncMap className="map" paths={[]} points={[point]} innerRef={mapRef} />
          )
        }
      </div>
    </StyledLocation>
  );
}
