import React, { RefObject, ReactNode } from 'react';
import styled from 'styled-components';

const ButtonStyled = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  touch-action: manipulation;
`;

interface Props {
  children: ReactNode;
  className?: string;
  innerRef?: RefObject<HTMLInputElement>;
  label: string;
  onClick: () => void;
}

export default function ButtonIcon(props: Props) {
  const {
    children, className, innerRef, label, onClick,
  } = props;

  return (
    <ButtonStyled ref={innerRef} className={className} aria-label={label} onClick={onClick}>
      {children}
    </ButtonStyled>
  );
}
